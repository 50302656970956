import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAh3X6h7cWbfbyz_jXeQpZxQbZEucyFfx0",
  authDomain: "skystreamcharters.firebaseapp.com",
  databaseURL: "https://skystreamcharters-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "skystreamcharters",
  storageBucket: "skystreamcharters.appspot.com",
  messagingSenderId: "159154425095",
  appId: "1:159154425095:web:0c09b4e70c6103152989a2"
  // all of this was copied from my firestore database, produced code that i copied and pasted on here
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
