import { Box, Container, Drawer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../../Images/Images/logo.png";
import { theme } from "../../Colours/colour";
import { Link } from "react-router-dom";
// import { AuthContext } from "../../Context/AuthContext";

import MenuIcon from "@mui/icons-material/Menu";
import { DrawerList } from "./DrawerList";

// Header component
const Header = () => {
  // Context
  // const { currentUser } = useContext(AuthContext);

  // State variables
  const [scrolled, setScrolled] = useState(false);
  const [open, setOpen] = React.useState(false);

  // Toggle drawer function
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // Effect for handling scroll event
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box sx={[style.header, { top: scrolled && 0 }]}>
      {/* Header container */}
      <Container
        sx={[
          {
            maxWidth: { lg: "1400px" },
            background: scrolled
              ? theme.secondary
              : "rgba(255, 255, 255, 0.15)",
          },
          style.mainMenu,
        ]}
      >
        {/* Row for header content */}
        <Box sx={style.row}>
          {/* Logo */}
          <Box sx={style.logoBox}>
            <Link to={"/"} style={{ textDecoration: "none", color: "inherit" }}>
              <img
                src={logo}
                style={{ width: "120px", height: "auto" }}
                alt="oops"
              />
            </Link>
          </Box>
          {/* Navigation links */}
          <Box sx={style.nav}>
            <Typography sx={style.color}>
              <Link
                to={"/"}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Home
              </Link>{" "}
            </Typography>
            <Typography sx={style.color}>
              <Link
                to={"/Customers"}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Customer
              </Link>
            </Typography>
            <Typography sx={style.color}>
              <Link
                to={"/Aircrafts"}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Administrator
              </Link>
            </Typography>
          </Box>
          {/* Mobile menu icon */}
          <Box sx={style.mobileMenu} onClick={toggleDrawer(true)}>
            <MenuIcon sx={{ color: "white", fontSize: 25 }} />
          </Box>
          {/* Drawer component */}
          <Drawer open={open} onClose={toggleDrawer(false)}>
            <DrawerList onClick={toggleDrawer(false)} />
          </Drawer>
        </Box>
      </Container>
    </Box>
  );
};

// Styles 
const style = {
  header: {
    position: "fixed",
    left: 0,
    top: 30,
    right: 0,
    transition: "all 0.5s",
    zIndex: 997,
  },
  mainMenu: {
    paddingBottom: "20px",
    paddingTop: "20px",
    paddingLeft: { sm: "15px", xs: 1 },
    paddingRight: { sm: "15px", xs: 1 },
    transition: "all ease-out 0.3s",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logoBox: {
    paddingLeft: "15px",
  },
  nav: {
    paddingRight: "15px",
    display: { sm: "flex", xs: "none" },
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
  },
  mobileMenu: {
    paddingRight: "15px",
    display: { sm: "none", xs: "flex" },
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
    gap: "20px",
  },
  color: {
    color: "white",
    fontFamily: "Poppins",
    cursor: "pointer",
    transition: "all ease-out 0.5s",
    ":hover": {
      color: theme.secondary,
    },
  },
};

export default Header;
