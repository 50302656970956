import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { theme } from "../../Colours/colour";
import logo from "../../Images/Images/logo.png";

import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

// Footer component
const Footer = () => {
  return (
    <>
      {/* Footer container */}
      <Box sx={style.box}>
        {/* Footer content */}
        <Container sx={{ maxWidth: { lg: "1400px" } }}>
          {/* Grid container */}
          <Grid container columnSpacing={5} xs={{ height: "100%" }}>
            {/* Logo */}
            <Grid
              item
              lg={3}
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={logo}
                style={{ width: "250px", height: "auto" }}
                alt="oops"
              />
            </Grid>
            {/* About section */}
            <Grid item lg={9}>
              <Typography sx={style.heads}>Experience the Heights of Luxury</Typography>
              <Typography sx={style.desc}>
                Introducing Skystream Charters, the best option for private jet
                sharing that combines unmatched service, efficiency, and
                elegance. At Skystream Charters, we're committed to providing a
                first-rate, accessible, and exclusive air travel experience. Our
                fleet of cutting-edge aircraft is designed to meet the diverse
                needs of our discriminating customers by providing a smooth
                blend of privacy, speed, and luxury. Our versatile jet sharing
                approach guarantees that you may take advantage of the
                advantages of private jet travel at a fraction of the price,
                whether you're flying for business, pleasure, or anything in
                between. Come along with us as we reinvent the sky with our
                dedication to quality, security, and individualized service.
                Welcome to Skystream Charters, a place where travel goes
                beyond the typical. 
              </Typography> {/* the paragraph above is chatgpt, used the prompt: make me a paragraph of welcoming users to my company sky stream charters*/}
            </Grid>
            {/* Navigation links */}
            {/* <Grid item lg={3}>
              <Typography sx={style.heads}>NAVIAGTION LINKS</Typography> // possibility to have navigation links in footer.
              <Typography sx={style.desc}>Home</Typography> 
              <Typography sx={style.desc}>Customer</Typography>
              <Typography sx={style.desc}>Administrator</Typography>
            </Grid> */}
          </Grid>
          {/* Horizontal rule */}
          <hr />
          {/* Copyright section */}
          <Box sx={style.copright}>
            <Typography sx={style.desc}>
              Copyright ©2024 All rights reserved |
            </Typography>
            {/* Social media icons */}
            <Box sx={style.flex}>
              <FacebookIcon sx={style.icon} />
              <LinkedInIcon sx={style.icon} />
              <InstagramIcon sx={style.icon} />
              <TwitterIcon sx={style.icon} />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

// Styles for the component
const style = {
  box: {
    paddingTop: "8rem",
    backgroundColor: theme.secondary,
    paddingBottom: "2rem",
  },
  heads: {
    color: "white",
    fontWeight: 600,
    fontSize: "22px",
    fontFamily: "poppins",
    paddingBottom: "14px",
    letterSpacing: "1px",
  },
  desc: {
    fontFamily: "poppins",
    color: "white",
    opacity: 0.7,
    transition: "all ease-out 0.2s",
    paddingBottom: "10px",
    cursor: "pointer",
    ":hover": {
      opacity: 0.9,
    },
  },
  copright: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "10px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    justifyContent: "center",
  },
  icon: {
    color: "white",
    transition: "all ease-out 0.3s",
    ":hover": {
      opacity: 0.7,
    },
  },
};

export default Footer;
