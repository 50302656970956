import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import bg from "../../Images/Images/bg.jpeg";
import { theme } from "../../Colours/colour";
import toast from "react-hot-toast";
import AdminDashboard from "../../Components/common/AdminDashboard";
import { AddAirCraft } from "../../Services/AddData";

const Index = () => {
  // State variables for form data
  const [formData, setFormData] = useState({
    capacity: 0,
    name: "",
  });

  // Function to handle form input changes
  const handleOnChange = (key, val) => {
    setFormData((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  // Function to handle form submission
  const onSubmit = async () => {
    const { capacity, name } = formData;

    // Check if all fields are filled
    if (capacity === 0 || name === "") {
      toast.error("Fill All Fields");
      return;
    }

    try {
      const { res } = await AddAirCraft(formData);
      if (res === true) {
        toast.success("Add Aircraft successfully");
        // Reset form data after successful submission
        setFormData({
          capacity: 0,
          name: "",
        });
      } else {
        toast.error("Add Aircraft Error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  
  return (
    <>
      <AdminDashboard>
        <Box sx={style.bg}>
          <Box sx={style.LoginBox}>
            <Typography sx={style.heading}>Add Aircraft</Typography>
            {/* Input for aircraft name */}
            <div className="section">
              <input
                className="input"
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={(e) => handleOnChange("name", e.target.value)}
                placeholder="Aircraft Name"
                required
              />
              <label className="label" htmlFor="name">
                Aircraft Name
              </label>
              <div className="error"></div>
            </div>

            {/* Input for aircraft capacity */}
            <div className="section">
              <input
                className="input"
                name="capacity"
                id="capacity"
                value={formData.capacity}
                onChange={(e) => handleOnChange("capacity", Number(e.target.value))}
                type="number"
                placeholder="Capacity"
                required
              />
              <label className="label" htmlFor="capacity">
                Capacity
              </label>
              <div className="error"></div>
            </div>

            {/* Button for submitting form */}
            <Button variant="contained" sx={style.btn} onClick={onSubmit}>
              ADD
            </Button>
          </Box>
        </Box>
      </AdminDashboard>
    </>
  );
};

export default Index;
const style = {
  bg: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100%",
    backgroundSize: "cover",
    backgroundImage: `url(${bg})`,
  },
  LoginBox: {
    
    width: { lg: "40%", md: "40%", sm: "80%", xs: "100%" },
    borderRadius: "1rem",
    padding: "1.3rem",
    background: "rgba(0, 0, 0, 0.3)",
    boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.2)",
    border: "1px solid rgba(255, 255, 255, 0.18)",
    backdropFilter: "blur(4px)",
    WebkitBackdropFilter: "blur(4px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  container: {
    maxWidth: { lg: "1400px" },
    height: "919px",
    display: "flex",
    alignItems: "center",
    position: "relative",
    zIndex: 1,
  },
  heading: { 
    width: "100%",
    textAlign: "center",
    fontSize: "30px",
    fontWeight: 600,
    color: "white",
    fontFamily: "poppins",
    paddingBottom: "25px",
    opacity: 0.7,
  },
  btn: { // button
    fontWeight: 600,
    fontSize: 15,
    paddingX: "20px",
    paddingY: "10px",
    fontFamily: "Poppins",
    marginTop: "1rem",
    background: theme.secondary,
  },
};
