"use client";
import { createContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../Firebase/Config";

// Create a context for authentication
export const AuthContext = createContext();

// Authentication context provider component
export const AuthContextProvider = ({ children }) => {
  // State to store the current user
  const [currentUser, setCurrentUser] = useState({});
  // State to track the admin status
  const [admin, setAdmin] = useState(() => {
    // Initialize admin status from local storage
    return localStorage.getItem("admin") === "true";
  });

  // Effect to subscribe to authentication state changes
  useEffect(() => {
    // Unsubscribe function 
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // Update current user state
      setCurrentUser(user);
      console.log(user);
    });

    // Clean up function to unsubscribe when component unmounts
    return () => {
      unsubscribe();
    };
  }, []);

  // Provide the authentication context to children components
  return (
    <AuthContext.Provider value={{ currentUser, admin, setAdmin }}>
      {children}
    </AuthContext.Provider>
  );
};
