import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import bg from "../../Images/Images/bg.jpeg";
import { Link } from "react-router-dom";
import { SignUpFirebase } from "../../Services/Authentication";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { theme } from "../../Colours/colour";


// Initial state of the sign up form before inputs
const initialState = {
  name: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
};

// SignUp component
const SignUp = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialState);

  // Function to handle form input changes
  const handleOnChange = (key, val) => {
    setFormData((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  // Function to handle form submission
  const onSubmit = async () => {
    const { name, email, phone, password, confirmPassword } = formData; // all the inputs for the form data
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // what the email should look like
    const capitalLetterRegex = /[A-Z]/; // making sure it includes a capital letter between A-Z
    if (
      name === "" ||
      email === "" ||
      phone === "" ||
      password === "" ||
      confirmPassword === ""
    ) {
      toast.error("Fill All Fields"); // validation error thrown because all fields arent filled in 
      return;
    }
    if (password.length > 8 && !capitalLetterRegex.test(password)) {
      toast.error("Password must include Capital Letter"); // validation error thrown because a capital letter isnt included in the password
      return;
    }
    if (phone.length > 13 || phone.length < 11) {
      toast.error("Phone number should be between 11-12 digits"); // phone number length should be between 11-12 digits
      return;
    }

    if (
      !emailPattern.test(email) ||
      password !== confirmPassword ||
      confirmPassword.length < 8 // password length has to be GREATER than 8 characters
    ) {
      toast.error("Validation Error");
    } else {
      try {
        const { res } = await SignUpFirebase(formData);
        if (res === true) {
          toast.success("Signup Successful");
          navigate("/Customers");
        } else if (res === false) {
          toast.error("Already Registered"); // checks firebase, if credentials arent there then it completes sign up, if not it comes back saying its already registered.
        }
      } catch (error) {
        console.error(error, "error");
      }
    }
  };


  return (
    <>
      <Box sx={style.bg}>
        <Box sx={style.LoginBox}>
          <Typography sx={style.heading}>Sign Up</Typography>
          <div class="section">
            <input
              className="input"
              name="name"
              id="name"
              value={formData.name}
              onChange={(e) => handleOnChange("name", e.target.value)}
              type="text"
              placeholder="Enter Your Full Name"
              required
            />
            <label class="label" htmlFor="name">
              Enter Your Full Name
            </label>
            <div class="error"></div>
          </div>

          {/* Input field for name above */}
          

          {/* Email input field */}
          <div class="section">
            <input
              className="input"
              name="email"
              id="email"
              value={formData.email}
              onChange={(e) => handleOnChange("email", e.target.value)}
              type="email"
              placeholder="Enter Your Email"
              required
            />
            <label class="label" htmlFor="email">
              Enter Your Email
            </label>
            <div class="error"></div>
          </div>

          {/* Phone input field */}
          <div class="section">
            <input
              className="input"
              name="phone"
              id="phone"
              value={formData.phone}
              onChange={(e) => handleOnChange("phone", e.target.value)}
              type="text"
              placeholder="Enter Your Phone"
              required
            />
            <label class="label" htmlFor="phone">
              Enter Your Phone
            </label>
            <div class="error"></div>
          </div>

          {/* Password input field */}
          <div class="section">
            <input
              className="input"
              name="password"
              id="password"
              value={formData.password}
              onChange={(e) => handleOnChange("password", e.target.value)}
              type="password"
              placeholder="Enter Your Password"
              required
            />
            <label class="label" htmlFor="password">
              Enter Your Password
            </label>
            <div class="error"></div>
          </div>

          {/* Confirm password input field */}
          <div class="section">
            <input
              className="input"
              name="confirmPassword"
              id="confirmPassword"
              value={formData.confirmPassword}
              onChange={(e) =>
                handleOnChange("confirmPassword", e.target.value)
              }
              type="password"
              placeholder="Confirm Password"
              required
            />
            <label class="label" htmlFor="confirmPassword">
              Confirm Password
            </label>
            <div class="error"></div>
          </div>

          {/* Button to submit the form */}
          <Button variant="contained" sx={style.btn} onClick={onSubmit}>
            Register
          </Button>

          {/* Link to the login page */}
          <Typography sx={style.slogan}>
            Already have an account?
            <Link
              to={"/Login"}
              style={{ textDecoration: "none", color: theme.secondary }}
            >
              Login
            </Link>
          </Typography>

          {/* Link back to the home page */}
          <Link to={"/"} style={{ textDecoration: "none", color: "inherit" }}>
            <Button variant="contained" sx={style.btn}>
              Back to Home page
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};


const style = {
  bg: { // background
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    backgroundSize: "cover",
    backgroundImage: `url(${bg})`,
  },
  LoginBox: {
    width: { lg: "20%", md: "40%", sm: "80%", xs: "100%" },
    borderRadius: "1rem",
    padding: "1.3rem",
    background: "rgba(0, 0, 0, 0.3)",
    boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.2)",
    border: "1px solid rgba(255, 255, 255, 0.18)",
    backdropFilter: "blur(4px)",
    WebkitBackdropFilter: "blur(4px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  heading: {
    width: "100%",
    textAlign: "center",
    fontSize: "30px",
    fontWeight: 600,
    color: "white",
    fontFamily: "poppins",
    paddingBottom: "25px",
    opacity: 0.7,
  },
  slogan: {
    width: "100%",
    textAlign: "center",
    fontSize: "17px",
    fontWeight: 600,
    color: "white",
    fontFamily: "poppins",
    paddingTop: "15px",
    opacity: 0.7,
  },
  btn: { // button
    fontWeight: 600,
    fontSize: 15,
    paddingX: "20px",
    paddingY: "10px",
    fontFamily: "Poppins",
    marginTop: "1rem",
    background: theme.secondary,
  },
};

export default SignUp;
