
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { auth, db } from "../Firebase/Config";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDoc,
} from "firebase/firestore";

const provider = new GoogleAuthProvider();

// chatgpt was helpful from 20 to 79 (not every line used during these lines were chatgpt but chatgpt was definetly a guide)
// Here is the chatgpt prompt: 
// Create me a firebase authentication function for react js which will include login and signup also add authentication data to firestore aswell.
export const SignUpFirebase = async (obj) => {

  // login Signup object 
  const { name, email, phone, password } = obj;

  try {
    // create a user form firebase function 
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
      //if user is created successfully update profile to add name and phonenumber
    if (user) {
      await updateProfile(user, {
        displayName: name,
        phoneNumber: phone,
      });
      //add users in firestore to save them
      await addDoc(collection(db, "users"), {
        name: name,
        phone: phone,
        email: email,
        uid: user.uid,
      });

      return { res: true };
    }
  } catch (error) {
    console.error(error);

    return { res: false };
  }
};

//login firebase
export const LoginFirebase = async (obj) => {
  //  email and password 
  const { email, password } = obj;

  try {
    // create  email and password 
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    console.log(userCredential);

    return { success: true, user: userCredential.user };
  } catch (error) {
    console.error(error);

    const errorCode = error.code;
    const errorMessage = error.message;

    return { success: false, errorCode, errorMessage };
  }
};
//admin login function 
export const AdminLogin = async (form) => {
  // check email and password 
  const { email, password } = form; 
  // check if collection of 38PNHfZibn76b9GJ2sbQ 
  const docRef = doc(db, "admin", "38PNHfZibn76b9GJ2sbQ");

  try {
    const snap = await getDoc(docRef);
    // if exists compare user typed email and password if yes then return true
    if (
      snap.exists() &&
      snap.data().email === email &&
      snap.data().password === password
    ) {
      console.log("admin Authentication successful");
    // if exists compare user typed email and password 
      return { res: true };
    } else {
      // if false then return false 
      console.log(" adminAuthentication failed");
      return { res: false };
    }
  } catch (error) {
    console.log(error, "error");
    return { res: false };
  }
};
//login with Google function 
export const signInGoogle = async () => {
 signInWithPopup(auth, provider)
   .then(async (result) => {
    
     const credential = GoogleAuthProvider.credentialFromResult(result);
     const token = credential.accessToken;
   //   The signed-in user info.
     const user = result.user;
     if (user) {
       await updateProfile(user, {
         displayName: user.displayName,
         phoneNumber: user.phoneNumber,
        });

        await addDoc(collection(db, "users"), {
          name: user.displayName,
          phone: user.phoneNumber,
          email: user.email,
          uid: user.uid,
        });

        window.location.href = "/Customers";

        return { res: true };
      }
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.customData.email;
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log(errorCode, errorMessage, email, credential);
    });
};
