import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import bg from "../../Images/Images/bg.jpeg";
import { theme } from "../../Colours/colour";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AdminLogin, LoginFirebase } from "../../Services/Authentication";
import { AuthContext } from "../../Context/AuthContext";


// Initial state for login form
const initialState = {
  email: "",
  password: "",
};

// Login component
const Login = ({ admin }) => {
  const navigate = useNavigate();
  const { setAdmin } = useContext(AuthContext);

  // State variables
  const [formData, setFormData] = useState(initialState);

  // Function to handle form input changes
  const handleOnChange = (key, val) => {
    setFormData((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  // Function to handle form submission
  const onSubmit = async () => {
    if (formData.email === "" || formData.password === "") {
      toast.error("Fill All Fields"); // validation thrown to make sure that all fields are filled
      return;
    }
    if (admin === true) {
      const { res } = await AdminLogin(formData);
      if (res === true) {
        toast.success("Admin Login Successful"); // admin login success if the email and password are correct
        localStorage.setItem("admin", true);
        const value = localStorage.getItem("admin") === "true";
        setAdmin(value);
      } else {
        toast.error("Invalid");
        localStorage.setItem("admin", false); // admin login failure if the email and password are incorrect
        const value = localStorage.getItem("admin") === "true";
        setAdmin(value);
      }
    } else {
      const response = await LoginFirebase(formData);
      if (response.success === true) {
        toast.success("Login Successful");// customer log in success if the email and password are correct
        navigate("/Customers"); // takes the customer to the customer page after being logged in
      } else if (response.success === false) {
        toast.error("Invalid Credentials"); // customer log in failure if the email and password are incorrect
      }
    }
  };

  
  return (
    <>
      <Box sx={style.bg}>
        <Box sx={style.LoginBox}>
          <Typography sx={style.heading}>
            {admin ? "ADMIN LOG IN" : "LOG IN"}
          </Typography>
          <div class="section">
            <input
              className="input"
              type="text"
              name="email"
              id="email"
              value={formData.email}
              onChange={(e) => handleOnChange("email", e.target.value)}
              placeholder="Enter Your Email"
              required
            />
            <label class="label" htmlFor="email">
              Your Email
            </label>
            <div class="error"></div>
          </div>

          <div class="section">
            <input
              className="input"
              name="password"
              id="password"
              value={formData.password}
              onChange={(e) => handleOnChange("password", e.target.value)}
              type="password"
              placeholder="Enter Your Password"
              required
            />
            <label class="label" htmlFor="password">
              Your Password
            </label>
            <div class="error"></div>
          </div>

          <Button variant="contained" sx={style.btn} onClick={onSubmit}>
            LOG IN
          </Button>
          
          {/* Link to registration page */}
          {!admin && (
            <Typography sx={style.slogan}>
              Don't have an account?
              <Link
                to={"/SignUp"}
                style={{ textDecoration: "none", color: theme.secondary }}
              >
                Register
              </Link>
            </Typography>
          )}
          {/* Link back to home page */}
          <Link to={"/"} style={{ textDecoration: "none", color: "inherit" }}>
            <Button variant="contained" sx={style.btn}>
              Back to Home page
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

// Styles for the login component
const style = {
  bg: { // background
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    backgroundSize: "cover",
    backgroundImage: `url(${bg})`,
  },
  LoginBox: {
    width: { lg: "20%", md: "40%", sm: "80%", xs: "100%" },
    borderRadius: "1rem",
    padding: "1.3rem",
    background: "rgba(0, 0, 0, 0.3)",
    boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.2)",
    border: "1px solid rgba(255, 255, 255, 0.18)",
    backdropFilter: "blur(4px)",
    WebkitBackdropFilter: "blur(4px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  heading: {
    width: "100%",
    textAlign: "center",
    fontSize: "30px",
    fontWeight: 600,
    color: "white",
    fontFamily: "poppins",
    paddingBottom: "25px",
    opacity: 0.7,
  },
  slogan: {
    width: "100%",
    textAlign: "center",
    fontSize: "17px",
    fontWeight: 600,
    color: "white",
    fontFamily: "poppins",
    paddingTop: "15px",
    opacity: 0.7,
  },
  btn: { // button
    fontWeight: 600,
    fontSize: 15,
    paddingX: "20px",
    paddingY: "10px",
    fontFamily: "Poppins",
    marginTop: "1rem",
    background: theme.secondary,
  },
};

export default Login;
