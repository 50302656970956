import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import bg from "../../Images/Images/bg.jpeg";
import Header from "../../Components/common/Header";
import Pricing from "./Pricing";
import ConatctUs from "./ConatctUs";
import Footer from "../../Components/common/Footer";

const Index = () => {
  return (
    <>
      <Header />
      <Box sx={style.bannerSection}>
        <Box sx={style.bannerOverlay}></Box>
        <Container sx={style.container}>
          <Box
            sx={{
              display: {
                md: "block",
                xs: "flex",
              },
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={style.minorText}
              data-aos="fade-right"
              data-aos-offset="100"
            >
              Welcome!
            </Typography>
            <Typography data-aos="fade-right" sx={style.mainText}> 
            Elevate Your Journey
            </Typography>
            <Typography
              sx={{
                color: "white",
                width: { md: "50%", sx: "100%" },
                fontSize: 17,
                marginBottom: 2,
                textAlign: { md: "left", xs: "center" },
              }}
              data-aos="fade-right"
              data-aos-offset="200"
              // the paragraph below is from chatgpt, used the prompt: make me a paragraph of welcoming users to my company sky stream charters//
            >
              Introducing Skystream Charters, the premier choice for private jet sharing that combines unparalleled service, efficiency, and elegance. 
              At Skystream Charters, we are committed to providing a top-tier, accessible, and exclusive air travel experience.
               Our fleet of state-of-the-art aircraft is designed to meet the varied needs of our discerning clients by offering a seamless blend of privacy, speed, and luxury. 
               Our flexible jet sharing model ensures that you can enjoy the benefits of private jet travel at a fraction of the cost, whether you're travelling for business, pleasure, or anything in between. 
               Join us as we redefine the skies with our commitment to quality, safety, and personalised service. 
               Welcome to Skystream Charters, where travel transcends the ordinary.
            </Typography> {}
            
          </Box>
        </Container>
      </Box>
      {/* <-------------Pricing Section Start------------------> */}
      <Pricing />
      {/* <-------------Pricing Section End------------------> */}
      {/* <-------------Conatct Us Section Start------------------> */}
      <ConatctUs />
      {/* <-------------Conatct Us Section End------------------> */}
      <Footer />
    </>
  );
};

export default Index;
const style = {
  bannerSection: {
    position: "relative",
    display: "block",
    backgroundSize: "cover",
    backgroundImage: `url(${bg})`,
  },
  bannerOverlay: {
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
    background: "rgba(4, 9, 30, 0.4)",
  },
  container: {
    maxWidth: { lg: "1400px" },
    height: "919px",
    display: "flex",
    alignItems: "center",
    position: "relative",
    zIndex: 1,
  },
  minorText: {
    textTransform: "uppercase",
    fontWeight: 600,
    fontSize: 17,
    letterSpacing: 2,
    color: "white",
    fontFamily: "Poppins",
  },
  mainText: {
    fontSize: { md: 60, sm: 40, xs: 30 },
    textTransform: "uppercase",
    color: "white",
    fontWeight: 800,
    fontFamily: "Poppins",
  },
  btn: {
    fontWeight: 600,
    fontSize: 15,
    paddingX: "20px",
    paddingY: "10px",
    fontFamily: "Poppins",
  },
};
