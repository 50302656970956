import * as React from "react";
import Button from "@mui/material/Button";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { getData, getSpcecificFlight } from "../../Services/ReadData";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { theme } from "../../Colours/colour";
import toast from "react-hot-toast";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../Firebase/Config";


// Functional component for editing flight details
export default function BasicModal({ open, onClose, id }) {
  // State variables initialisation
  const [airport, setAirport] = React.useState([]); // State for airports data
  const [aircraft, setAircraft] = React.useState([]); // State for aircrafts data

  // State for form data
  const [formData, setFormData] = React.useState({});

  // Function to handle form input changes
  const handleOnChange = (key, val) => {
    setFormData((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  // Function to handle form submission
  const onSubmit = async () => {
    const {
      arrivalAirport,
      departureAirport,
      arrivalTimeDate,
      departureTimeDate,
      aircraft,
    } = formData;

    // Checking if all fields are filled
    if (
      arrivalAirport === "" ||
      departureAirport === "" ||
      arrivalTimeDate === "" ||
      departureTimeDate === "" ||
      aircraft === ""
    ) {
      toast.error("Fill All Fields");
      console.log("Fill all fields");
      return;
    }

    // Updating flight details in firebase database
    const docRef = doc(db, "flights", id);
    try {
      const res = await updateDoc(docRef, formData);
      toast.success("Flight Update successfully");
      setFormData({});
      onClose();
      console.log(res);
    } catch (error) {
      console.log(error);
      toast.success("Flight Update Error");
    }
  };

  // Function to fetch airports and aircrafts data
  const getsData = async () => {
    try {
      const airportData = await getData("airports");
      console.log(airportData, "airportData");
      setAirport(airportData);
      const aircraftData = await getData("aircrafts");
      setAircraft(aircraftData);
    } catch (error) {
      console.log("error", error);
    }
  };

  // useEffect to fetch data on component mount
  React.useEffect(() => {
    getsData();
  }, []);

  // useEffect to fetch specific flight data when id changes
  React.useEffect(() => {
    if (id) {
      getSpcecificFlight(id).then((data) => {
        console.log(data);
        setFormData(data);
      });
    }
  }, [open, id]);

  // Logging formData when it changes
  React.useEffect(() => {
    console.log(formData, "form");
  }, [formData]);

  // JSX
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          ".MuiDialog-paper": {
            padding: "1.3rem",
            background: "rgba(0, 0, 0, 0.6)",
            boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.2)",
            border: "1px solid rgba(255, 255, 255, 0.18)",
            backdropFilter: "blur(20px)",
            borderRadius: "15px",
            WebkitBackdropFilter: "blur(20px)",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontFamily: "'poppins'",
            color: "white",
            fontSize: "25px",
            fontWeight: 600,
          }}
        >
          Edit Flight
        </DialogTitle>
        <DialogContent>
          {/* Select dropdown for selecting aircraft */}
          <FormControl fullWidth sx={styles.formControl}>
            <InputLabel id="demo-simple-select-label">Add Aircraft</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formData?.aircraft?.id}
              label="Add Aircraft"
              fullWidth
              onChange={(e) =>
                handleOnChange(
                  "aircraft",
                  aircraft.find((item) => item.id === e.target.value)
                )
              }
              sx={styles.select}
            >
              {aircraft?.map((data) => (
                <MenuItem key={data.id} value={data.id}>
                  {data?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Select dropdown for selecting arrival airport */}
          <FormControl fullWidth sx={styles.formControl}>
            <InputLabel id="demo-simple-select-label">
              Add Arrival Airport
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formData?.arrivalAirport?.id}
              label="Add Arrival Airport"
              fullWidth
              onChange={(e) =>
                handleOnChange(
                  "arrivalAirport",
                  airport?.find((item) => item.id === e.target.value)
                )
              }
              sx={styles.select}
            >
              {airport?.map((data) => (
                <MenuItem key={data.id} value={data.id}>
                  {data?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Input field for entering arrival time and date */}
          <div
            style={{
              marginTop: " 1.5rem",
              display: "grid",
              alignItems: "center",
              width: "100%",
            }}
          >
            <input
              type="datetime-local"
              id="birthday"
              name="birthday"
              className="input"
              value={formData?.arrivalTimeDate}
              onChange={(e) =>
                handleOnChange("arrivalTimeDate", e.target.value)
              }
            />
            <label className="label">Add Arrival Time & Date</label>
          </div>

          {/* Select dropdown for selecting departure airport */}
          <FormControl fullWidth sx={styles.formControl}>
            <InputLabel id="demo-simple-select-label">
              Add Departure Airport
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formData?.departureAirport?.id}
              label="Add Departure Airport"
              fullWidth
              onChange={(e) =>
                handleOnChange(
                  "departureAirport",
                  airport?.find((item) => item.id === e.target.value)
                )
              }
              sx={styles.select}
            >
              {airport?.map((data) => (
                <MenuItem key={data.id} value={data.id}>
                  {data?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Input field for entering departure time and date */}
          <div
            style={{
              marginTop: " 1.5rem",
              display: "grid",
              alignItems: "center",
              width: "100%",
            }}
          >
            <input
              type="datetime-local"
              id="birthday"
              name="birthday"
              className="input"
              value={formData?.departureTimeDate}
              onChange={(e) =>
                handleOnChange("departureTimeDate", e.target.value)
              }
            />
            <label className="label">Add Departure Time & Date</label>
          </div>

          {/* Update button */}
          <Button variant="contained" sx={style.btn} onClick={onSubmit}>
            UPDATE
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

// Styling for the component
const style = {
  btn: {
    fontWeight: 600,
    fontSize: 15,
    paddingX: "20px",
    paddingY: "10px",
    fontFamily: "Poppins",
    marginTop: "1rem",
    width: "100%",
    background: theme.secondary,
  },
};

// Styles for form elements
const styles = {
  formControl: {
    "& .MuiInputLabel-root": {
      color: "white !important", // Label colour
    },
    marginTop: "1.5rem",
  },
  select: {
    "& .MuiOutlinedInput-input": {
      color: "white", // Text colour
    },
    "& .MuiInputLabel-outlined": {
      color: "white !important", // Label colour
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white !important", // Border colour
      borderRadius: "7px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "white !important", // Hover border colour
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white !important", // Focused border colour
    },
    "& .MuiSelect-icon": {
      color: "white", // Dropdown icon colour
    },
    "& .MuiListItem-root": {
      color: "white", // Text colour in options
    },
    input: {
      color: "white", // Text colour
      border: "2px solid white", // Border colour
      borderRadius: "4px", // Border radius
      padding: "8px", // Padding
      width: "100%", // Width
      boxSizing: "border-box", // Ensure padding and border are included in width
    },
    wrapper: {
      width: "100%", // Set full width
      marginBottom: "16px", // Add margin bottom for spacing
    },
  },
};
