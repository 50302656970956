import Home from "../src/pages/Home"; // Importing Home component
import Customers from "../src/pages/Customers"; // Importing Customers component
import BookedFlights from "../src/pages/BookedFlights"; // Importing BookedFlights component
import Airports from "../src/pages/Airports"; // Importing Airports component
import Aircrafts from "../src/pages/Aircrafts"; // Importing Aircrafts component
import ViewFlights from "../src/pages/ViewFlights"; // Importing ViewFlights component
import AddFlights from "../src/pages/AddFlights"; // Importing AddFlights component
import BookedFlightsAdmin from "../src/pages/BookedFlightsAdmin"; // Importing BookedFlightsAdmin component
import AdminContact from "../src/pages/ContactFormAdmin/AdminContact"; // Importing AdminContact component
import "./App.css"; // Importing CSS file for App
import { Routes, Route } from "react-router-dom"; // Importing Routes and Route from react-router-dom
import AOS from "aos"; // Importing AOS library for animations
import "aos/dist/aos.css"; // Importing AOS CSS
import Login from "./Components/Auth/Login"; // Importing Login component
import SignUp from "./Components/Auth/SignUp"; // Importing SignUp component
import { Toaster } from "react-hot-toast"; // Importing toast notifications
import { useContext } from "react"; // Importing useContext hook
import { AuthContext } from "./Context/AuthContext"; // Importing AuthContext for authentication
AOS.init(); // Initialising AOS library

function App() {
  const { admin } = useContext(AuthContext); 

  
  const protectedRoute = (component) => {
    console.log(admin, "admin");
    if (admin) {
      return component;
    } else {
      return <Login admin={true} />;
    }
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} /> {/* Route for Home */}
        <Route path="/Aircrafts" element={protectedRoute(<Aircrafts />)} />{" "}
        {/* Route for Aircrafts */}
        <Route path="/Customers" element={<Customers />} />{" "}
        {/* Route for Customers */}
        <Route path="/Login" element={<Login admin={false} />} />{" "}
        {/* Route for Login */}
        <Route path="/SignUp" element={<SignUp />} /> {/* Route for SignUp */}
        <Route path="/Airports" element={protectedRoute(<Airports />)} />{" "}
        {/* Route for Airports */}
        <Route
          path="/AddFlights"
          element={protectedRoute(<AddFlights />)}
        />{" "}
        {/* Route for AddFlights */}
        <Route
          path="/ViewFlights"
          element={protectedRoute(<ViewFlights />)}
        />{" "}
        {/* Route for ViewFlights */}
        <Route
          path="/book-flights-admin"
          element={protectedRoute(<BookedFlightsAdmin />)}
        />{" "}
        {/* Route for BookedFlightsAdmin */}
        <Route path="/Leads" element={protectedRoute(<AdminContact />)} />{" "}
        {/* Route for AdminContact */}
        <Route path="/book-flights" element={<BookedFlights />} />{" "}
        {/* Route for BookedFlights */}
      </Routes>
      <Toaster /> {/* Toast notifications component */}
    </>
  );
}

export default App;
